import { Box, Button, Card, Container, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

const tiers = [
  {
    title: "Free",
    price: "£0",
    description: [
      "5x AI Question Searches Per Week",
      "1x AI Exam Paper Per Week",
      "1x AI Exam Correction",
      "2x AI Mnemonic Creation Per Week",
    ],
    buttonText: "Sign up for free",
    buttonVariant: "outlined",
    buttonUrl: "/signuppage",
  },
  {
    title: "Premium",
    price: "£4.49 p/m",
    description: [
      "Recommended",
      "Unlimited AI Question Searches",
      "Unlimited AI Exam Papers",
      "IAI and Old Syllabus Exams",
      "Premium Search Filters and Parameters",
      "1x AI Exam Correction per day",
      "Better correction model for more accuracy",
      "Unlimited Mnemonic Creation",
    ],
    buttonText: "Start now",
    buttonVariant: "contained",
    popular: true,
    buttonUrl: "/signuppage",
  },
  {
    title: "Enterprise",
    price: "Contact Us",
    description: [
      "Provide your actuarial student employees with the newest study tool",
    ],
    buttonText: "Contact us",
    buttonVariant: "outlined",
    buttonUrl: "/contactpage",
  },
];

export default function Pricing() {
  const theme = useTheme();

  return (
    <Container
      id="pricing"
      sx={{
        py: { xs: 4, sm: 12 },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 3,
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Typography variant="h4" color="text.primary" align="center" gutterBottom>
        Pricing
      </Typography>
      <Typography variant="body1" color="text.secondary" align="center">
        Choose the plan that fits your needs.
      </Typography>

      <Grid container spacing={3} justifyContent="center">
        {tiers.map((tier) => (
          <Grid item key={tier.title} xs={12} sm={6} md={4}>
            <Card
              sx={{
                p: 3,
                boxShadow: tier.popular
                  ? `0px 4px 20px ${theme.palette.primary.main}`
                  : `0px`,
                background: tier.popular
                  ? `radial-gradient(circle, ${theme.palette.background.default}, ${theme.palette.grey[900]})`
                  : `radial-gradient(circle, ${theme.palette.background.default}, ${theme.palette.grey[900]})`,
              }}
            >
              <Typography variant="h6" align="center">
                {tier.title}
              </Typography>
              <Typography variant="h2" align="center" gutterBottom>
                {tier.price}
              </Typography>
              <Box>
                {tier.description.map((line) => (
                  <Box
                    key={line}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      mb: 1,
                    }}
                  >
                    <CheckCircleRoundedIcon
                      sx={{
                        width: 20,
                        color: theme.palette.primary.main,
                      }}
                    />
                    <Typography variant="subtitle2">{line}</Typography>
                  </Box>
                ))}
              </Box>
              <Button
                fullWidth
                variant="contained"
                href={tier.buttonUrl} // Map the URL to the button
                sx={{
                  mt: 2,
                  color: tier.popular
                    ? theme.palette.primary.contrastText
                    : theme.palette.secondary.contrastText,
                  backgroundColor: tier.popular
                    ? theme.palette.primary.main
                    : theme.palette.secondary.main,
                  "&:hover": {
                    backgroundColor: tier.popular
                      ? theme.palette.primary.dark
                      : theme.palette.secondary.dark,
                    borderColor: tier.popular
                      ? undefined
                      : theme.palette.secondary.light,
                    color: tier.popular
                      ? theme.palette.primary.contrastText
                      : theme.palette.secondary.contrastText,
                  },
                }}
              >
                {tier.buttonText}
              </Button>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
