import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useTheme } from "@mui/material/styles";
import Radio from "@mui/material/Radio";
import { motion } from "framer-motion";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import mammoth from "mammoth";
import LinearProgress from "@mui/material/LinearProgress";
import {
  createClient,
  FunctionsHttpError,
  FunctionsRelayError,
  FunctionsFetchError,
} from "@supabase/supabase-js";

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL as string;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY as string;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

type Exam = {
  code: string;
  name: string;
  dbCode: string;
};

//prettier-ignore
const examsList: Exam[] = [
    { code: "CS1 Paper 1", name: "Actuarial Statistics", dbCode: "CS1A" },
    { code: "CS1 Paper 2", name: "Actuarial Statistics", dbCode: "CS1B" },
    { code: "CS2 Paper 1", name: "Risk Modelling and Survival Analysis", dbCode: "CS2A" },
    { code: "CS2 Paper 2", name: "Risk Modelling and Survival Analysis", dbCode: "CS2B" },
    { code: "CM1 Paper 1", name: "Actuarial Mathematics", dbCode: "CM1A" },
    { code: "CM1 Paper 2", name: "Actuarial Mathematics", dbCode: "CM1B" },
    { code: "CM2 Paper 1", name: "Financial Engineering and Loss Reserving", dbCode: "CM2A" },
    { code: "CM2 Paper 2", name: "Financial Engineering and Loss Reserving", dbCode: "CM2B" },
    { code: "CB1", name: "Business Finance", dbCode: "CB1" },
    { code: "CB2", name: "Business Economics", dbCode: "CB2" },
    { code: "CP1 Paper 1", name: "Actuarial Practice", dbCode: "CP1 1" },
    { code: "CP1 Paper 2", name: "Actuarial Practice", dbCode: "CP1 2" },
    { code: "CP3", name: "Communications Practice", dbCode: "CP3" },
    { code: "SP1", name: "Health and Care Principles", dbCode: "SP1" },
    { code: "SP2", name: "Life Insurance Principles", dbCode: "SP2" },
    { code: "SP4", name: "Pensions and other benefits Principles", dbCode: "SP4" },
    { code: "SP5", name: "Investment and Finance Principles", dbCode: "SP5" },
    { code: "SP6", name: "Financial Derivatives Principles", dbCode: "SP6" },
    { code: "SP7", name: "General Insurance Reserving and Capital Modelling", dbCode: "SP7" },
    { code: "SP8", name: "General Insurance Pricing Principles", dbCode: "SP8" },
    { code: "SP9", name: "Enterprise Risk Management Principles", dbCode: "SP9" },
    { code: "SA1", name: "Health and Care Advanced", dbCode: "SA1" },
    { code: "SA2", name: "Life Insurance Advanced", dbCode: "SA2" },
    { code: "SA3", name: "General Insurance Advanced", dbCode: "SA3" },
    { code: "SA4", name: "Pensions and other benefits Advanced", dbCode: "SA4" },
    { code: "SA7", name: "Investment and Finance Advanced", dbCode: "SA7" }
  ];

type Sitting = {
  Year: number;
  Month: string;
  MonthNumber: number;
};

//prettier-ignore
const IFOA_Sittings: Sitting[] = [
    { Year: 2024, Month: "April", MonthNumber: 4 },
    { Year: 2023, Month: "September", MonthNumber: 9 },
    { Year: 2023, Month: "April", MonthNumber: 4 },
    { Year: 2022, Month: "September", MonthNumber: 9 },
    { Year: 2022, Month: "April", MonthNumber: 4 },
    { Year: 2021, Month: "September", MonthNumber: 9 },
    { Year: 2021, Month: "April", MonthNumber: 4 },
    { Year: 2020, Month: "September", MonthNumber: 9 },
    { Year: 2020, Month: "April", MonthNumber: 4 },
    { Year: 2019, Month: "September", MonthNumber: 9 },
    { Year: 2019, Month: "April", MonthNumber: 4 }
  ];

//prettier-ignore
const IAI_Sittings: Sitting[] = [
    { Year: 2024, Month: "May", MonthNumber: 5 },
    { Year: 2023, Month: "November", MonthNumber: 11 },
    { Year: 2023, Month: "May", MonthNumber: 5 },
    { Year: 2022, Month: "December", MonthNumber: 12 },
    { Year: 2022, Month: "July", MonthNumber: 7 },
    { Year: 2022, Month: "March", MonthNumber: 3 },
    { Year: 2021, Month: "September", MonthNumber: 9 },
    { Year: 2021, Month: "March", MonthNumber: 3 },
    { Year: 2020, Month: "November", MonthNumber: 11 },
    { Year: 2019, Month: "November", MonthNumber: 11 },
    { Year: 2019, Month: "June", MonthNumber: 6 }
  ];

interface ExamCorrectorProps {
  premiumEnabled: boolean;
  correctionsRL: number;
  correctionsResetRL: string;
}

const ExamCorrector: React.FC<ExamCorrectorProps> = ({
  premiumEnabled,
  correctionsRL,
  correctionsResetRL,
}) => {
  const [selectedExam, setSelectedExam] = useState<string>("");
  const [selectedSitting, setSelectedSitting] = useState<{
    year: number;
    monthCode: string;
  } | null>(null);
  const [institute, setInstitute] = useState<string>("IFOA");
  const [docxText, setDocxText] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [progressText, setProgressText] = useState<string>("");
  const [correctionResult, setCorrectionResult] = useState<{
    passMark: number;
    overallFeedback: string;
    questionFeedback: {
      question: string;
      markAwarded: number;
      detailedFeedback: string;
    }[];
  } | null>(null);
  const [totalMarks, setTotalMarks] = useState<number>(0);
  const [passFailResult, setPassFailResult] = useState<"Pass" | "Fail">("Pass");

  const theme = useTheme();

  const handleExamChange = (event: SelectChangeEvent) => {
    setSelectedExam(event.target.value);
  };

  const handleSittingChange = (event: SelectChangeEvent) => {
    const [year, monthNumber] = event.target.value.split(" ");
    const sitting = currentSittings.find(
      (s) =>
        s.Year === parseInt(year) && s.MonthNumber === parseInt(monthNumber)
    );
    if (sitting) {
      setSelectedSitting({
        year: sitting.Year,
        monthCode: monthNumber, // Store the MonthNumber
      });
    }
  };

  const currentSittings = institute === "IFOA" ? IFOA_Sittings : IAI_Sittings;

  const handleDocxUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        const arrayBuffer = await file.arrayBuffer();
        const result = await mammoth.extractRawText({ arrayBuffer });
        setCorrectionResult(null);
        setDocxText(result.value);
      } catch (error) {
        console.error("Error extracting .docx file:", error);
      }
    }
  };

  const startLoadingProcess = () => {
    setLoading(true);
    setErrorMessage("");
    setProgressText("Starting search...");
    const progressSteps = [
      "Sending your exam to our servers",
      "Getting the solution to the paper",
      "Marking your paper",
      "Providing feedback",
      "Using AI Magic",
    ];

    progressSteps.forEach((text, index) => {
      setTimeout(() => setProgressText(text), index * 10000);
    });
  };

  const handleSendForCorrection = async () => {
    if (!institute || !selectedExam || !selectedSitting || !docxText) {
      setErrorMessage("Please fill all the fields and upload a .docx file.");
      return;
    }

    startLoadingProcess();

    try {
      const payload = {
        institute,
        exam: selectedExam,
        year: selectedSitting.year,
        month: selectedSitting.monthCode,
        attempt: docxText,
      };

      setDocxText("");

      const { data, error } = await supabase.functions.invoke("correct_exam", {
        body: JSON.stringify(payload),
      });

      if (error) {
        if (error instanceof FunctionsHttpError) {
          const errorMessage = await error.context.json();
          setErrorMessage(errorMessage.error || "HTTP Error occurred");
          setLoading(false);
        } else if (error instanceof FunctionsRelayError) {
          setErrorMessage(`Relay error: ${error.message}`);
          setLoading(false);
        } else if (error instanceof FunctionsFetchError) {
          setErrorMessage(`Fetch error: ${error.message}`);
          setLoading(false);
        } else {
          setErrorMessage("An unknown error occurred.");
          setLoading(false);
        }
        return;
      }

      // Parse the result from the response (it's a JSON string)
      const parsedResult = JSON.parse(data.results);

      // Set the parsed correction result
      setCorrectionResult(parsedResult);

      //Set total marks
      const calculatedTotalMarks = parsedResult.questionFeedback.reduce(
        (a: number, b: { markAwarded: number }) => a + b.markAwarded,
        0
      );
      setTotalMarks(calculatedTotalMarks);

      //Set pass fail
      const passMark = parsedResult.passMark;
      const isPass = passMark !== undefined && calculatedTotalMarks >= passMark;
      setPassFailResult(isPass ? "Pass" : "Fail");

      setLoading(false);
    } catch (err) {
      setErrorMessage((err as Error).message);
      setLoading(false);
    }
  };

  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      sx={{
        p: 4,
        borderRadius: 2,
        backgroundColor: theme.palette.grey[900],
        boxShadow: theme.shadows[3],
        maxWidth: "60%",
        mx: "auto",
        px: { xs: 2, md: 4 },
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        sx={{ color: theme.palette.text.primary }}
      >
        Exam Corrector
      </Typography>

      {/* Institute Radio */}
      <Box sx={{ display: "flex", flexDirection: "row", gap: 3, mb: 3 }}>
        <FormControl>
          <FormLabel>Institute</FormLabel>
          <RadioGroup
            value={institute}
            onChange={(event) => setInstitute(event.target.value)}
            name="radio-buttons-group"
          >
            <FormControlLabel value="IFOA" control={<Radio />} label="IFOA" />
            <FormControlLabel value="IAI" control={<Radio />} label="IAI" />
          </RadioGroup>
        </FormControl>
      </Box>

      {/* Exam Dropdown */}
      <InputLabel id="select-exams">Select Exam</InputLabel>
      <Select
        label="Select Exam"
        value={selectedExam}
        onChange={handleExamChange}
        input={<OutlinedInput placeholder="Select Exam" />}
        sx={{
          width: "100%",
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.divider,
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.primary.light,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.primary.main,
          },
        }}
        MenuProps={{
          disableAutoFocusItem: true,
          PaperProps: {
            sx: {
              backgroundColor: theme.palette.background.default,
              color: theme.palette.text.primary,
              maxHeight: 300,
            },
          },
        }}
      >
        {examsList.map((exam, index) => (
          <MenuItem
            key={index}
            value={exam.code}
          >{`${exam.code} - ${exam.name}`}</MenuItem>
        ))}
      </Select>

      {/* Sitting Dropdown */}
      <InputLabel id="select-sittings" sx={{ mt: 3 }}>
        Select Sitting
      </InputLabel>
      <Select
        label="Select Sitting"
        value={
          selectedSitting
            ? `${selectedSitting.year} ${selectedSitting.monthCode}` // Use monthCode (which stores the MonthNumber)
            : ""
        }
        onChange={handleSittingChange}
        input={<OutlinedInput placeholder="Select Sitting" />}
        sx={{
          width: "100%",
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.divider,
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.primary.light,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.primary.main,
          },
        }}
        MenuProps={{
          disableAutoFocusItem: true,
          PaperProps: {
            sx: {
              backgroundColor: theme.palette.background.default,
              color: theme.palette.text.primary,
              maxHeight: 300,
            },
          },
        }}
      >
        {currentSittings.map((sitting, index) => (
          <MenuItem
            key={index}
            value={`${sitting.Year} ${sitting.MonthNumber}`}
          >
            {`${sitting.Year} - ${sitting.Month}`}{" "}
            {/* Display the Month name */}
          </MenuItem>
        ))}
      </Select>

      {/*Rate Limits */}
      {correctionsRL > 0 &&
        premiumEnabled &&
        new Date(correctionsResetRL) < new Date() && (
          <Typography
            variant="body1"
            sx={{ color: theme.palette.primary.main, mt: 3 }}
          >
            Used {correctionsRL}/1 daily corrections. Resetting at{" "}
            {correctionsResetRL}. Please refresh the page to get the latest
            information.
          </Typography>
        )}

      {correctionsRL > 0 && !premiumEnabled && (
        <Typography
          variant="body1"
          sx={{ color: theme.palette.primary.main, mt: 3 }}
        >
          Used {correctionsRL}/1 corrections. Please upgrade to premium to
          access more corrections and a more accurate correction model.
        </Typography>
      )}

      <Typography
        variant="body1"
        sx={{ color: theme.palette.text.secondary, mt: 3 }}
      >
        AI Models can make mistakes
      </Typography>

      {/* Upload and Submit Buttons */}
      <Box sx={{ display: "flex", flexDirection: "row", gap: 2, mt: 3 }}>
        <Button
          component="label"
          variant="contained"
          startIcon={<CloudUploadIcon />}
          disabled={loading}
        >
          Upload Attempt (.docx)
          <input
            type="file"
            hidden
            accept=".docx"
            onChange={handleDocxUpload}
          />
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSendForCorrection}
          disabled={loading}
        >
          Send for correction
        </Button>
      </Box>

      {docxText && (
        <Box sx={{ mt: 3 }}>
          <Typography variant="h6" sx={{ color: theme.palette.text.primary }}>
            Extracted Text from your .docx:
          </Typography>
          <Typography
            sx={{ color: theme.palette.text.secondary, whiteSpace: "pre-wrap" }}
          >
            {docxText}
          </Typography>
        </Box>
      )}

      {loading && (
        <Box sx={{ mt: 4 }}>
          <LinearProgress color="primary" />
          <Typography
            variant="body1"
            sx={{ color: theme.palette.text.primary, mt: 2 }}
            align="center"
          >
            {progressText}
          </Typography>
        </Box>
      )}

      {errorMessage && (
        <Box sx={{ mt: 3 }}>
          <Typography
            variant="body1"
            sx={{ color: theme.palette.primary.main }}
          >
            {errorMessage}
          </Typography>
        </Box>
      )}

      {correctionResult && (
        <Box
          sx={{
            mt: 3,
            p: 2,
            border: "1px solid",
            borderColor: theme.palette.divider,
          }}
        >
          <Typography variant="h6" sx={{ color: theme.palette.text.primary }}>
            Result: {passFailResult} with {totalMarks} marks
          </Typography>
          <Typography sx={{ fontWeight: "bold" }}>Overall Feedback:</Typography>
          <Typography sx={{ color: theme.palette.text.secondary }}>
            {correctionResult.overallFeedback}
          </Typography>
          <Box>
            {correctionResult.questionFeedback.map((qFeedback, index) => (
              <Box key={index} sx={{ mt: 2 }}>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  {qFeedback.question} - {qFeedback.markAwarded} marks awarded
                </Typography>
                <Typography sx={{ color: theme.palette.text.secondary }}>
                  Feedback: {qFeedback.detailedFeedback}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ExamCorrector;
