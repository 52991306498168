import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useTheme } from "@mui/material/styles";
import { motion } from "framer-motion";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Slider from "@mui/material/Slider";
import LinearProgress from "@mui/material/LinearProgress";
import {
  createClient,
  FunctionsHttpError,
  FunctionsRelayError,
  FunctionsFetchError,
} from "@supabase/supabase-js";

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL as string;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY as string;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

type Exam = {
  code: string;
  name: string;
  oldCode: string;
};

type SearchResult = {
  id: number;
  institute: string;
  exam: string;
  year: number;
  month: string;
  question_number: string;
  subquestion: string;
  question_text: string;
  match_likelihood: number;
};

//prettier-ignore
const examsList: Exam[] = [
  { code: "CS1A", name: "Actuarial Statistics", oldCode: "CT3" },
  { code: "CS2A", name: "Risk Modelling and Survival Analysis", oldCode: "CT3" },
  { code: "CM1A", name: "Actuarial Mathematics for Modelling", oldCode: "CT1, CT5" },
  { code: "CM2A", name: "Economic Modelling", oldCode: "CT1, CT5" },
  { code: "CB1", name: "Business Finance", oldCode: "CT2" },
  { code: "CB2", name: "Business Economics", oldCode: "CT7" },
  { code: "CB3", name: "Business Management", oldCode: "CT9" },
  { code: "CP1", name: "Actuarial Practice", oldCode: "CA1 1, CA1 2" },
  { code: "SP1", name: "Health and Care Principles", oldCode: "ST1" },
  { code: "SP2", name: "Life Insurance Principles", oldCode: "ST2" },
  { code: "SP4", name: "Pensions and other benefits Principles", oldCode: "ST4" },
  { code: "SP5", name: "Investment and Finance Principles", oldCode: "ST5" },
  { code: "SP6", name: "Financial Derivatives Principles", oldCode: "ST6" },
  { code: "SP7", name: "General Insurance Reserving and Capital Modelling Principles", oldCode: "ST7" },
  { code: "SP8", name: "General Insurance Pricing Principles", oldCode: "ST8" },
  { code: "SP9", name: "Enterprise Risk Management Principles", oldCode: "ST9" },
  { code: "SA1", name: "Health and Care Advanced", oldCode: "" },
  { code: "SA2", name: "Life Insurance Advanced", oldCode: "" },
  { code: "SA3", name: "General Insurance Advanced", oldCode: "" },
  { code: "SA4", name: "Pensions and other benefits Advanced", oldCode: "" },
  { code: "SA7", name: "Investment and Finance Advanced", oldCode: "SA5, SA6" }
];

interface ExamSearchProps {
  premiumEnabled: boolean;
  searchRL: number;
  searchResetRL: string;
  examRL: number;
  examResetRL: string;
}

const ExamSearch: React.FC<ExamSearchProps> = ({
  premiumEnabled,
  searchRL,
  searchResetRL,
  examRL,
  examResetRL,
}) => {
  const [selectedExams, setSelectedExams] = useState<string[]>([]);
  const [oldSyllabus, setOldSyllabus] = useState<boolean>(false);
  const [includeIFOA, setIncludeIFOA] = useState<boolean>(true);
  const [includeIAI, setIncludeIAI] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [progressText, setProgressText] = useState<string>("");
  const [maxResponses, setMaxResponses] = useState<number>(5);
  const [likelihoodCutoff, setLikelihoodCutoff] = useState<number>(50);

  const theme = useTheme();

  const handleExamChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    const selected = typeof value === "string" ? value.split(",") : value;
    setSelectedExams(
      selected.includes("all") ? examsList.map((exam) => exam.code) : selected
    );
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const startLoadingProcess = () => {
    setLoading(true);
    setErrorMessage("");
    setSearchResults([]);
    setProgressText("Starting search...");
    const progressSteps = [
      "Converting your text to an embedding",
      "Applying your filters",
      "Searching our database",
      "Using AI Magic",
    ];

    progressSteps.forEach((text, index) => {
      setTimeout(() => setProgressText(text), index * 3000);
    });
  };

  const handleFindSimilarQuestions = async () => {
    // Validate required inputs
    if (
      !selectedExams.length ||
      !searchQuery.trim() ||
      (!includeIFOA && !includeIAI)
    ) {
      setErrorMessage("Please fill out all required fields.");
      return;
    }

    startLoadingProcess();

    // Prepare exam list with old syllabus codes
    let examsToSearch = [...selectedExams];
    if (oldSyllabus) {
      const oldCodes = examsList
        .filter((exam) => selectedExams.includes(exam.code) && exam.oldCode)
        .flatMap((exam) => exam.oldCode.split(", "));
      examsToSearch = [...new Set([...examsToSearch, ...oldCodes])];
    }

    // Prepare institute list
    const selectedInstitutes: string[] = [];
    if (includeIFOA) selectedInstitutes.push("IFOA");
    if (includeIAI) selectedInstitutes.push("IAI");

    // Build payload for the request
    const payload = {
      query: searchQuery,
      match_likelihood: likelihoodCutoff / 100,
      exam_list: examsToSearch,
      institute_list: selectedInstitutes,
      max_responses: maxResponses,
    };

    try {
      // Retrieve session and token
      const {
        data: { session },
      } = await supabase.auth.getSession();

      if (!session || !session.access_token) {
        throw new Error("Authentication error: Please log in to continue.");
      }

      // Make the API call
      const { data, error } = await supabase.functions.invoke("search_query", {
        body: JSON.stringify(payload),
      });

      setLoading(false);

      if (error) {
        if (error instanceof FunctionsHttpError) {
          const errorMessage = await error.context.json();
          setErrorMessage(errorMessage.error || "HTTP Error occurred");
        } else if (error instanceof FunctionsRelayError) {
          setErrorMessage(`Relay error: ${error.message}`);
        } else if (error instanceof FunctionsFetchError) {
          setErrorMessage(`Fetch error: ${error.message}`);
        } else {
          setErrorMessage("An unknown error occurred.");
        }
        return;
      }

      // Successfully retrieved search results
      setSearchResults(data.results);
      setErrorMessage(""); // Clear any previous error messages
    } catch (err) {
      setLoading(false);

      // Handle unexpected errors
      let errorMessage = "An unknown error occurred during search.";

      if (err instanceof Error) {
        errorMessage = err.message;

        // Try parsing the error message if it's in JSON format
        try {
          const parsedError = JSON.parse(err.message);
          errorMessage = parsedError?.error || errorMessage;
        } catch {
          // If parsing fails, fallback to the default error message
        }
      }

      // Set the error message to be displayed
      setErrorMessage(errorMessage);
    }
  };

  const handleGenerateExamPaper = async () => {
    // Validate required inputs
    if (
      !selectedExams.length ||
      !searchQuery.trim() ||
      (!includeIFOA && !includeIAI)
    ) {
      setErrorMessage("Please fill out all required fields.");
      return;
    }

    startLoadingProcess();

    // Prepare exam list with old syllabus codes
    let examsToSearch = [...selectedExams];
    if (oldSyllabus) {
      const oldCodes = examsList
        .filter((exam) => selectedExams.includes(exam.code) && exam.oldCode)
        .flatMap((exam) => exam.oldCode.split(", "));
      examsToSearch = [...new Set([...examsToSearch, ...oldCodes])];
    }

    // Prepare institute list
    const selectedInstitutes = [];
    if (includeIFOA) selectedInstitutes.push("IFOA");
    if (includeIAI) selectedInstitutes.push("IAI");

    // Build payload for the request
    const payload = {
      query: searchQuery,
      match_likelihood: likelihoodCutoff / 100,
      exam_list: examsToSearch,
      institute_list: selectedInstitutes,
      max_responses: maxResponses,
    };

    try {
      // Retrieve session and token
      const {
        data: { session },
      } = await supabase.auth.getSession();

      if (!session || !session.access_token) {
        throw new Error("Authentication error: Please log in to continue.");
      }

      // Make the API call to generate the exam paper
      const response = await fetch(
        "https://pfgjagzspzakuipgeowf.supabase.co/functions/v1/generate_exam",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${session.access_token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      setLoading(false);

      if (!response.ok) {
        const errorData = await response.json();

        // Handle the specific "rate limit exceeded" error or other errors
        if (errorData.error) {
          throw new Error(errorData.error);
        } else {
          throw new Error("Error generating exam.");
        }
      }

      // Process the response data
      const data = await response.arrayBuffer();
      const blob = new Blob([data], { type: "application/pdf" });
      const now = new Date();
      const formattedDate = now.toISOString().slice(0, 10).replace(/-/g, "");
      const formattedTime = now.toTimeString().slice(0, 5).replace(":", "");
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `ActQExam_${formattedDate}_${formattedTime}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);

      setErrorMessage(""); // Clear any previous error messages
    } catch (err) {
      setLoading(false);

      // Handle unexpected errors
      let errorMessage = "An unknown error occurred during exam generation.";

      if (err instanceof Error) {
        errorMessage = err.message;

        // Try parsing the error message if it's in JSON format
        try {
          const parsedError = JSON.parse(err.message);
          errorMessage = parsedError?.error || errorMessage;
        } catch {
          // If parsing fails, fallback to the default error message
        }
      }

      // Set the error message to be displayed
      setErrorMessage(errorMessage);
    }
  };

  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      sx={{
        p: 4,
        borderRadius: 2,
        backgroundColor: theme.palette.grey[900],
        boxShadow: theme.shadows[3],
        maxWidth: "60%",
        mx: "auto",
        px: { xs: 2, md: 4 },
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        sx={{ color: theme.palette.text.primary }}
      >
        Exam Search
      </Typography>
      <Box sx={{ mb: 3 }}>
        <InputLabel id="select-exams">Select Exams</InputLabel>
        <Select
          label="Select exams"
          multiple
          value={selectedExams}
          onChange={handleExamChange}
          input={<OutlinedInput placeholder="Select Exams" />}
          renderValue={(selected) => (selected as string[]).join(", ")}
          sx={{
            width: "100%",
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.divider,
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.primary.light,
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.primary.main,
            },
          }}
          MenuProps={{
            disableAutoFocusItem: true,
            PaperProps: {
              sx: {
                backgroundColor: theme.palette.background.default,
                color: theme.palette.text.primary,
                maxHeight: 300,
              },
            },
          }}
        >
          <MenuItem value="all" sx={{ color: theme.palette.text.primary }}>
            <Checkbox
              checked={selectedExams.length === examsList.length}
              sx={{ color: theme.palette.primary.main }}
            />
            <ListItemText
              primary="Select All"
              sx={{ color: theme.palette.text.primary }}
            />
          </MenuItem>
          {examsList.map((exam) => (
            <MenuItem
              key={exam.code}
              value={exam.code}
              sx={{ color: theme.palette.text.primary }}
            >
              <Checkbox
                checked={selectedExams.indexOf(exam.code) > -1}
                sx={{ color: theme.palette.primary.main }}
              />
              <ListItemText
                primary={`${exam.code} - ${exam.name}`}
                sx={{ color: theme.palette.text.primary }}
              />
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 3,
        }}
      >
        <FormControlLabel
          control={
            <Switch
              checked={includeIFOA}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setIncludeIFOA(event.target.checked)
              }
              color="primary"
            />
          }
          label="Include IFOA exams?"
          sx={{ color: theme.palette.text.primary }}
        />
      </Box>

      <Typography
        variant="h6"
        gutterBottom
        sx={{ color: theme.palette.text.primary, mt: 4 }}
      >
        Premium Filters
      </Typography>

      {/* Maximum Responses and Likelihood Cutoff Sliders in a row */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: 2,
          flexWrap: "wrap",
        }}
      >
        {/* Maximum Responses */}
        <Box sx={{ width: "48%" }}>
          <InputLabel id="max-responses">Maximum Responses</InputLabel>
          <TextField
            variant="outlined"
            type="number"
            value={maxResponses}
            onChange={(e) => setMaxResponses(Number(e.target.value))}
            inputProps={{ min: 1, max: 10 }}
            disabled={!premiumEnabled}
            sx={{
              width: "100%",
              backgroundColor: theme.palette.background.default,
              color: theme.palette.text.primary,
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: theme.palette.divider,
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: theme.palette.primary.light,
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: theme.palette.primary.main,
              },
              mb: 1,
            }}
          />
          <Slider
            value={maxResponses}
            onChange={(_e, newValue) => setMaxResponses(newValue as number)}
            min={1}
            max={10}
            marks
            valueLabelDisplay="auto"
            disabled={!premiumEnabled}
          />
        </Box>

        {/* Likelihood Cutoff */}
        <Box sx={{ width: "48%" }}>
          <InputLabel id="likelihood-cutoff">
            Match Likelihood Cut-off (%)
          </InputLabel>
          <TextField
            variant="outlined"
            type="number"
            value={likelihoodCutoff}
            onChange={(e) => setLikelihoodCutoff(Number(e.target.value))}
            inputProps={{ min: 0, max: 100 }}
            disabled={!premiumEnabled}
            sx={{
              width: "100%",
              backgroundColor: theme.palette.background.default,
              color: theme.palette.text.primary,
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: theme.palette.divider,
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: theme.palette.primary.light,
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: theme.palette.primary.main,
              },
              mb: 1,
            }}
          />
          <Slider
            value={likelihoodCutoff}
            onChange={(_e, newValue) => setLikelihoodCutoff(newValue as number)}
            marks
            min={0}
            max={100}
            step={10}
            valueLabelDisplay="auto"
            disabled={!premiumEnabled}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 3,
        }}
      >
        <FormControlLabel
          control={
            <Switch
              checked={oldSyllabus}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setOldSyllabus(event.target.checked)
              }
              color="primary"
            />
          }
          label="Include old syllabus?"
          disabled={!premiumEnabled}
          sx={{ color: theme.palette.text.primary }}
        />

        <FormControlLabel
          control={
            <Switch
              checked={includeIAI}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setIncludeIAI(event.target.checked)
              }
              color="primary"
            />
          }
          label="Include IAI Exams?"
          disabled={!premiumEnabled}
          sx={{ color: theme.palette.text.primary }}
        />
      </Box>

      <Typography
        variant="h6"
        sx={{ color: theme.palette.text.primary, mt: 4 }}
      >
        Search
      </Typography>

      <InputLabel id="search-keywords">Enter keywords or topics</InputLabel>
      <TextField
        variant="outlined"
        value={searchQuery}
        onChange={handleSearchChange}
        fullWidth
        sx={{
          mb: 1,
          backgroundColor: theme.palette.background.default,
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: theme.palette.divider,
            },
            "&:hover fieldset": {
              borderColor: theme.palette.primary.light,
            },
            "&.Mui-focused fieldset": {
              borderColor: theme.palette.primary.main,
            },
          },
          "& .MuiInputLabel-root": {
            color: theme.palette.text.secondary,
          },
        }}
      />
      <Typography
        variant="body1"
        sx={{
          mb: 3,
          color: theme.palette.text.secondary,
        }}
      >
        For example "Define burning cost".
      </Typography>

      {searchRL > 0 &&
        !premiumEnabled &&
        new Date(searchResetRL) < new Date() && (
          <Typography
            variant="body1"
            sx={{ color: theme.palette.primary.main }}
          >
            Used {searchRL}/5 weekly searches. Resetting at {searchResetRL}.
            Please refresh the page to get the latest information.
          </Typography>
        )}
      {examRL > 0 && !premiumEnabled && new Date(examResetRL) < new Date() && (
        <Typography variant="body1" sx={{ color: theme.palette.primary.main }}>
          Used {examRL}/1 weekly exams. Resetting at {examResetRL}. Please
          refresh the page to get the latest information.
        </Typography>
      )}
      <Box sx={{ display: "flex", flexDirection: "row", gap: 2, mt: 3 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleFindSimilarQuestions}
          sx={{
            backgroundColor: theme.palette.primary.main,
            "&:hover": {
              backgroundColor: theme.palette.primary.dark,
            },
          }}
          disabled={loading} // Disable button when loading
        >
          Find Similar Questions
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleGenerateExamPaper}
          sx={{
            backgroundColor: theme.palette.secondary.main,
            "&:hover": {
              backgroundColor: theme.palette.secondary.dark,
            },
          }}
          disabled={loading} // Disable button when loading
        >
          Generate Exam Paper
        </Button>
      </Box>

      {loading && (
        <Box sx={{ mt: 4 }}>
          <LinearProgress color="primary" />
          <Typography
            variant="body1"
            sx={{ color: theme.palette.text.primary, mt: 2 }}
            align="center"
          >
            {progressText}
          </Typography>
        </Box>
      )}

      <Box sx={{ mt: 4 }}>
        {errorMessage && (
          <Typography
            variant="body1"
            sx={{ color: theme.palette.primary.main }}
          >
            {errorMessage}
          </Typography>
        )}
        {searchResults.length > 0 && (
          <Typography variant="h4" sx={{ color: theme.palette.text.primary }}>
            Search Results:
          </Typography>
        )}
        {searchResults.map((result) => (
          <Box
            key={result.id}
            sx={{
              p: 2,
              mt: 2,
              backgroundColor: theme.palette.background.default,
              borderRadius: 2,
            }}
          >
            <Typography variant="h5" sx={{ color: theme.palette.text.primary }}>
              <strong>{result.institute}</strong> - {result.exam} ({result.year}{" "}
              {result.month})
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: theme.palette.text.primary }}
            >
              {result.question_number} {result.subquestion}:{" "}
              {result.question_text}
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: theme.palette.text.primary }}
            >
              Match Likelihood: {(result.match_likelihood * 100).toFixed(2)}%
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ExamSearch;
