import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useTheme } from "@mui/material/styles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import { motion } from "framer-motion";
import {
  createClient,
  FunctionsHttpError,
  FunctionsRelayError,
  FunctionsFetchError,
} from "@supabase/supabase-js";

// Initialize Supabase client
const supabaseUrl = import.meta.env.VITE_SUPABASE_URL as string;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY as string;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

interface MnemonicMakerProps {
  premiumEnabled: boolean;
  mnemonicRL: number;
  mnemonicResetRL: string;
}

interface MnemonicResult {
  mnemonic: string;
  mnemonicExplanation: {
    mnemonicWord: string;
    wordRepresented: string;
    originalWord: string;
  }[];
}

interface AcronymResult {
  acronym: string;
  acronymExplanation: {
    acronymLetter: string;
    wordRepresented: string;
    originalWord?: string;
  }[];
}

const MnemonicMaker: React.FC<MnemonicMakerProps> = ({
  premiumEnabled,
  mnemonicRL,
  mnemonicResetRL,
}) => {
  const [mnemonicType, setMnemonicType] = useState<string>("Neutral");
  const [inputWords, setInputWords] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [result, setResult] = useState<MnemonicResult | AcronymResult | null>(
    null
  );
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [progressText, setProgressText] = useState<string>("");
  const [resultType, setResultType] = useState<"mnemonic" | "acronym">(
    "mnemonic"
  );

  const theme = useTheme();

  useEffect(() => {
    if (!premiumEnabled) {
      setMnemonicType("Neutral");
    }
  }, [premiumEnabled]);

  const handleMnemonicTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMnemonicType(event.target.value);
  };

  const handleGenerate = async (generateType: "mnemonic" | "acronym") => {
    if (!inputWords) {
      setErrorMessage("Please enter some words to create a mnemonic.");
      return;
    }

    setLoading(true);
    setErrorMessage("");
    setResult(null);
    setProgressText("Starting mnemonic generation...");
    setResultType(generateType);

    const progressSteps = [
      "Sending your request to the server",
      "Setting the type of mnemonic",
      "Generating mnemonic with AI",
      "Using AI Magic",
    ];

    progressSteps.forEach((text, index) => {
      setTimeout(() => setProgressText(text), index * 3000);
    });

    try {
      // Prepare payload
      const payload = {
        type: mnemonicType,
        words: inputWords,
        acronymOrMnemonic: generateType,
      };

      // Call Supabase function to generate mnemonic
      const { data, error } = await supabase.functions.invoke(
        "mnemonic_maker",
        {
          body: JSON.stringify(payload),
        }
      );

      setLoading(false);

      if (error) {
        if (error instanceof FunctionsHttpError) {
          const errorMessage = await error.context.json();
          setErrorMessage(errorMessage.error || "HTTP Error occurred");
        } else if (error instanceof FunctionsRelayError) {
          setErrorMessage(`Relay error: ${error.message}`);
        } else if (error instanceof FunctionsFetchError) {
          setErrorMessage(`Fetch error: ${error.message}`);
        } else {
          setErrorMessage("An unknown error occurred.");
        }
        return;
      }

      // Successfully retrieved search results
      const parsedData = JSON.parse(data.results);

      // Set the result from the response
      if (generateType === "mnemonic") {
        setResult(parsedData as MnemonicResult);
      } else {
        setResult(parsedData as AcronymResult);
      }

      setProgressText("");
    } catch (err) {
      setLoading(false);

      // Handle unexpected errors
      let errorMessage = "An unknown error occurred during mnemonic making.";

      if (err instanceof Error) {
        errorMessage = err.message;

        // Try parsing the error message if it's in JSON format
        try {
          const parsedError = JSON.parse(err.message);
          errorMessage = parsedError?.error || errorMessage;
        } catch {
          // If parsing fails, fallback to the default error message
        }
      }

      // Set the error message to be displayed
      setErrorMessage(errorMessage);
    }
  };

  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      sx={{
        p: 4,
        borderRadius: 2,
        backgroundColor: theme.palette.grey[900],
        boxShadow: theme.shadows[3],
        maxWidth: "60%",
        mx: "auto",
        px: { xs: 2, md: 4 },
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        sx={{ color: theme.palette.text.primary }}
      >
        Mnemonic Generator
      </Typography>

      {/* Mnemonic Type Radio */}
      <Box sx={{ display: "flex", flexDirection: "row", gap: 3, mb: 3 }}>
        <FormControl>
          <FormLabel>Select Mnemonic Type</FormLabel>
          <RadioGroup
            value={mnemonicType}
            onChange={handleMnemonicTypeChange}
            name="mnemonic-type-group"
          >
            <FormControlLabel
              value="Neutral"
              control={<Radio />}
              label="Neutral"
            />

            {!premiumEnabled && (
              <Typography
                variant="h6"
                gutterBottom
                sx={{ color: theme.palette.text.primary, mt: 4 }}
              >
                Premium Types
              </Typography>
            )}

            <FormControlLabel
              value="Actuarial"
              control={<Radio />}
              label="Actuarial"
              disabled={!premiumEnabled}
            />

            <FormControlLabel
              value="Funny"
              control={<Radio />}
              label="Funny"
              disabled={!premiumEnabled}
            />
            <FormControlLabel
              value="Musical"
              control={<Radio />}
              label="Musical"
              disabled={!premiumEnabled}
            />
            <FormControlLabel
              value="Sporty"
              control={<Radio />}
              label="Sporty"
              disabled={!premiumEnabled}
            />
          </RadioGroup>
        </FormControl>
      </Box>

      {/* Input Words TextField */}
      <InputLabel id="input-words" sx={{ mt: 3 }}>
        Enter words or phrases (seperated by a comma) to create a mnemonic or
        acronym from
      </InputLabel>
      <OutlinedInput
        value={inputWords}
        onChange={(e) => setInputWords(e.target.value)}
        fullWidth
        sx={{
          mb: 1,
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.divider,
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.primary.light,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.primary.main,
          },
        }}
      />
      <Typography
        variant="body1"
        sx={{ mb: 3, color: theme.palette.text.secondary }}
      >
        For example: "Size of company, legacy systems, management, integrity of
        data systems,...".
      </Typography>

      {mnemonicRL > 0 &&
        !premiumEnabled &&
        new Date(mnemonicResetRL) < new Date() && (
          <Typography
            variant="body1"
            sx={{ color: theme.palette.primary.main }}
          >
            Used {mnemonicRL}/2 weekly mnemonics. Resetting at {mnemonicResetRL}
            . Please refresh the page to get the latest information.
          </Typography>
        )}

      <Box sx={{ display: "flex", flexDirection: "row", gap: 2, mt: 3 }}>
        {/* Generate Mnemonic Button */}
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleGenerate("mnemonic")}
          disabled={loading}
        >
          Generate Mnemonic (Sentence)
        </Button>

        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleGenerate("acronym")}
          disabled={loading}
        >
          Generate Acronym (Word)
        </Button>
      </Box>

      {loading && (
        <Box sx={{ mt: 4 }}>
          <LinearProgress color="primary" />
          <Typography
            variant="body1"
            sx={{ color: theme.palette.text.primary, mt: 2 }}
            align="center"
          >
            {progressText}
          </Typography>
        </Box>
      )}

      {result && !loading && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h4" sx={{ color: theme.palette.text.primary }}>
            Your {resultType === "mnemonic" ? "Mnemonic" : "Acronym"}:
          </Typography>

          {resultType === "mnemonic" && "mnemonic" in result ? (
            <Box>
              <Typography variant="h6" sx={{ mb: 2, mt: 2 }}>
                {result.mnemonic}
              </Typography>

              <Typography
                variant="h6"
                sx={{ mb: 2, color: theme.palette.text.primary }}
              >
                Explanation:
              </Typography>

              {result.mnemonicExplanation.map((item, index) => (
                <Box key={index} sx={{ mb: 1 }}>
                  <Typography sx={{ display: "flex", gap: 1 }}>
                    <strong>{item.mnemonicWord}:</strong> {item.wordRepresented}
                    {item.originalWord &&
                      item.originalWord.toLowerCase() !==
                        item.wordRepresented.toLowerCase() && (
                        <Typography
                          sx={{ color: theme.palette.text.secondary }}
                        >
                          ({item.originalWord})
                        </Typography>
                      )}
                  </Typography>
                </Box>
              ))}
            </Box>
          ) : resultType === "acronym" && "acronym" in result ? (
            <Box>
              <Typography variant="h6" sx={{ mb: 2, mt: 2 }}>
                <strong>{result.acronym}</strong>
              </Typography>

              <Typography
                variant="h6"
                sx={{ mb: 2, color: theme.palette.text.primary }}
              >
                Explanation:
              </Typography>

              {result.acronymExplanation.map((item, index) => (
                <Box key={index} sx={{ mb: 1 }}>
                  <Typography sx={{ display: "flex", gap: 1 }}>
                    <strong>{item.acronymLetter}:</strong>{" "}
                    {item.wordRepresented}
                    {item.originalWord &&
                      item.originalWord.toLowerCase() !==
                        item.wordRepresented.toLowerCase() && (
                        <Typography
                          sx={{ color: theme.palette.text.secondary }}
                        >
                          ({item.originalWord})
                        </Typography>
                      )}
                  </Typography>
                </Box>
              ))}
            </Box>
          ) : null}
        </Box>
      )}

      {errorMessage && (
        <Box sx={{ mt: 3 }}>
          <Typography
            variant="body1"
            sx={{ color: theme.palette.primary.main }}
          >
            {errorMessage}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default MnemonicMaker;
