import { useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import searchVideo from "../../assets/ExamSearch.mp4";
import generateVideo from "../../assets/GenerateExamPaper.mp4";
import correctVideo from "../../assets/ExamCorrection.mp4";
import mnemonicVideo from "../../assets/MnemonicMaker.mp4";

const Features = () => {
  const theme = useTheme();
  const { ref: ref1, inView: inView1 } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  const { ref: ref2, inView: inView2 } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  const { ref: ref3, inView: inView3 } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  const { ref: ref4, inView: inView4 } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  // State for modals
  const [openModal1, setOpenModal1] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [openModal3, setOpenModal3] = useState(false);
  const [openModal4, setOpenModal4] = useState(false);

  // Handlers to open/close modals
  const handleOpenModal1 = () => setOpenModal1(true);
  const handleCloseModal1 = () => setOpenModal1(false);

  const handleOpenModal2 = () => setOpenModal2(true);
  const handleCloseModal2 = () => setOpenModal2(false);

  const handleOpenModal3 = () => setOpenModal3(true);
  const handleCloseModal3 = () => setOpenModal3(false);

  const handleOpenModal4 = () => setOpenModal4(true);
  const handleCloseModal4 = () => setOpenModal4(false);

  return (
    <Box
      component="section"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 4,
        padding: { xs: 4, md: 8 },
        marginTop: 4,
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Typography variant="h4" color="text.primary" align="center" gutterBottom>
        Features
      </Typography>
      <Typography variant="body1" color="text.secondary" align="center">
        Discover the tools that will transform your study experience.
      </Typography>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
          gap: 4,
          width: "100%",
        }}
      >
        {/* Feature 1: AI Question Search */}
        <motion.div
          ref={ref1}
          initial={{ opacity: 0, x: -100 }}
          animate={inView1 ? { opacity: 1, x: 0 } : {}}
          transition={{ duration: 1 }}
        >
          <Card
            sx={{
              padding: 2,
              borderRadius: 2,
              boxShadow: 3,
              background: `radial-gradient(circle, ${theme.palette.background.default}, ${theme.palette.grey[900]})`,
              color: theme.palette.text.primary,
            }}
          >
            <CardContent>
              <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
                AI Question Search
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Discover relevant questions quickly with our AI-powered search
                of over 40,000 past questions.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  color: theme.palette.primary.contrastText,
                  backgroundColor: theme.palette.primary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                    color: theme.palette.primary.contrastText,
                  },
                }}
                onClick={handleOpenModal1}
              >
                See it in action
              </Button>
            </CardContent>
          </Card>
        </motion.div>

        {/* Feature 2: AI Exam Maker */}
        <motion.div
          ref={ref2}
          initial={{ opacity: 0, x: 100 }}
          animate={inView2 ? { opacity: 1, x: 0 } : {}}
          transition={{ duration: 1 }}
        >
          <Card
            sx={{
              padding: 2,
              borderRadius: 2,
              boxShadow: 3,
              background: `radial-gradient(circle, ${theme.palette.background.default}, ${theme.palette.grey[900]})`,
              color: theme.palette.text.primary,
            }}
          >
            <CardContent>
              <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
                AI Exam Maker
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Generate customized exams with ease using our AI-driven exam
                maker, tailored to your specific learning needs.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  color: theme.palette.primary.contrastText,
                  backgroundColor: theme.palette.primary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                    color: theme.palette.primary.contrastText,
                  },
                }}
                onClick={handleOpenModal2}
              >
                See it in action
              </Button>
            </CardContent>
          </Card>
        </motion.div>

        {/* Feature 3: Exam Corrector */}
        <motion.div
          ref={ref3}
          initial={{ opacity: 0, y: 100 }}
          animate={inView3 ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1 }}
        >
          <Card
            sx={{
              padding: 2,
              borderRadius: 2,
              boxShadow: 3,
              background: `radial-gradient(circle, ${theme.palette.background.default}, ${theme.palette.grey[900]})`,
              color: theme.palette.text.primary,
            }}
          >
            <CardContent>
              <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
                AI Exam Corrector
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Upload your exam attempt, specify the subject and sitting, and
                receive instant feedback.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  color: theme.palette.primary.contrastText,
                  backgroundColor: theme.palette.primary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                    color: theme.palette.primary.contrastText,
                  },
                }}
                onClick={handleOpenModal3}
              >
                See it in action
              </Button>
            </CardContent>
          </Card>
        </motion.div>

        {/* Feature 4: Mnemonic Maker */}
        <motion.div
          ref={ref4}
          initial={{ opacity: 0, y: 100 }}
          animate={inView4 ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1 }}
        >
          <Card
            sx={{
              padding: 2,
              borderRadius: 2,
              boxShadow: 3,
              background: `radial-gradient(circle, ${theme.palette.background.default}, ${theme.palette.grey[900]})`,
              color: theme.palette.text.primary,
            }}
          >
            <CardContent>
              <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
                AI Mnemonic Maker
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Generate Mnemonics and Acronyms from words and phrases to help
                you remember during exams.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  color: theme.palette.primary.contrastText,
                  backgroundColor: theme.palette.primary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                    color: theme.palette.primary.contrastText,
                  },
                }}
                onClick={handleOpenModal4}
              >
                See it in action
              </Button>
            </CardContent>
          </Card>
        </motion.div>
      </Box>

      {/* Modal for Feature 1 (AI Question Search) */}
      <Dialog
        open={openModal1}
        onClose={handleCloseModal1}
        maxWidth="md"
        fullWidth
        sx={{
          "& .MuiBackdrop-root": {
            backdropFilter: "blur(8px)",
          },
          "& .MuiPaper-root": {
            background: theme.palette.background.default,
          },
        }}
      >
        <DialogTitle>AI Question Search</DialogTitle>
        <DialogContent>
          <Box sx={{ textAlign: "center" }}>
            {/* Lazy-loaded video */}
            <video width="100%" controls>
              <source src={searchVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Discover relevant questions quickly with our AI-powered search
              solution designed to optimize your study time.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            sx={{
              color: theme.palette.primary.contrastText,
              backgroundColor: theme.palette.primary.main,
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
                color: theme.palette.primary.contrastText,
              },
            }}
            onClick={handleCloseModal1}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal for Feature 2 (AI Exam Maker) */}
      <Dialog
        open={openModal2}
        onClose={handleCloseModal2}
        maxWidth="md"
        fullWidth
        sx={{
          "& .MuiBackdrop-root": {
            backdropFilter: "blur(8px)",
          },
          "& .MuiPaper-root": {
            background: theme.palette.background.default,
          },
        }}
      >
        <DialogTitle>AI Exam Maker</DialogTitle>
        <DialogContent>
          <Box sx={{ textAlign: "center" }}>
            {/* Lazy-loaded video */}
            <video width="100%" controls>
              <source src={generateVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Generate customized exams with ease using our AI-driven exam
              maker, tailored to your specific learning needs.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            sx={{
              color: theme.palette.primary.contrastText,
              backgroundColor: theme.palette.primary.main,
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
                color: theme.palette.primary.contrastText,
              },
            }}
            onClick={handleCloseModal2}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal for Feature 3 (Exam Corrector) */}
      <Dialog
        open={openModal3}
        onClose={handleCloseModal3}
        maxWidth="md"
        fullWidth
        sx={{
          "& .MuiBackdrop-root": {
            backdropFilter: "blur(8px)",
          },
          "& .MuiPaper-root": {
            background: theme.palette.background.default,
          },
        }}
      >
        <DialogTitle>AI Exam Corrector</DialogTitle>
        <DialogContent>
          <Box sx={{ textAlign: "center" }}>
            {/* Lazy-loaded video */}
            <video width="100%" controls>
              <source src={correctVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Upload your exam attempt, specify the subject and sitting, and
              receive instant feedback. Get detailed corrections and insights on
              areas for improvement to enhance your future performance.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            sx={{
              color: theme.palette.primary.contrastText,
              backgroundColor: theme.palette.primary.main,
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
                color: theme.palette.primary.contrastText,
              },
            }}
            onClick={handleCloseModal3}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal for Feature 4 (Mnemonic Maker) */}
      <Dialog
        open={openModal4}
        onClose={handleCloseModal4}
        maxWidth="md"
        fullWidth
        sx={{
          "& .MuiBackdrop-root": {
            backdropFilter: "blur(8px)",
          },
          "& .MuiPaper-root": {
            background: theme.palette.background.default,
          },
        }}
      >
        <DialogTitle>AI Mnemonic Maker</DialogTitle>
        <DialogContent>
          <Box sx={{ textAlign: "center" }}>
            {/* Lazy-loaded video */}
            <video width="100%" controls>
              <source src={mnemonicVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Input words and phrases and our AI will turn them into memorable
              mnemonics or acronyms to help you remember during exams.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            sx={{
              color: theme.palette.primary.contrastText,
              backgroundColor: theme.palette.primary.main,
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
                color: theme.palette.primary.contrastText,
              },
            }}
            onClick={handleCloseModal4}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Features;
